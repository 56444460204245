import { cva, type VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type ComponentType,
  type MouseEventHandler,
} from 'react';
import { Link } from 'react-router-dom';
import { InlineSpinner } from './InlineSpinner';

const buttonClasses = cva(
  [
    'relative inline-flex items-center justify-center rounded-full border-2 font-heading font-bold uppercase transition-all hover:brightness-90 disabled:opacity-50 [&_span]:gap-2 [&_svg]:shrink-0',
  ],
  {
    variants: {
      size: {
        xs: ['px-2 py-1.5 text-xs tracking-wider [&_svg]:size-3'],
        sm: ['px-4 py-2 text-xs tracking-wider [&_svg]:size-4'],
        md: ['px-6 py-3 text-sm [&_svg]:size-4'],
        lg: ['px-10 py-5 text-base [&_span]:gap-4 [&_svg]:size-6'],
      },
      variant: {
        primary: [
          'border-app-primary bg-app-primary text-app-primary-foreground',
        ],
        secondary: [
          'border-app-secondary bg-app-secondary text-app-secondary-foreground',
        ],
        tertiary: [
          'border-app-accent bg-app-accent text-app-accent-foreground',
        ],
        danger: ['border-app-red bg-app-red text-white hover:bg-app-red'],
        ghost: ['border-transparent bg-transparent text-app-gray'],
      },
      loading: {
        true: ['disabled:opacity-100'],
        false: ['disabled:opacity-50'],
      },
      outline: {
        true: ['!bg-transparent'],
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        outline: true,
        className: [
          'border-app-primary !text-app-text hover:!bg-app-primary hover:!text-app-primary-foreground',
        ],
      },
      {
        variant: 'secondary',
        outline: true,
        className: [
          'border-app-secondary !text-app-secondary hover:!bg-app-secondary hover:!text-app-secondary-foreground',
        ],
      },
      {
        variant: 'tertiary',
        outline: true,
        className: ['border-app-accent !text-app-accent'],
      },
      {
        variant: 'danger',
        outline: true,
        className: [
          'border-app-red !text-app-red hover:!bg-app-red hover:!text-white',
        ],
      },
      {
        variant: 'danger',
        outline: true,
        className: [
          'border-app-red !text-app-red hover:!bg-app-red hover:!text-white',
        ],
      },
    ],

    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

interface ButtonBodyProps {
  children: React.ReactNode | string;
  icon?: ComponentType<{ className?: string }>;
  loading?: boolean;
  /**
   * Label displayed after the loading is completed
   */
  actionCompleteLabel?: string;
  /**
   * How long the actionComplete label should be displayed.
   * Defaults to 2000ms.
   * Use 0 to disable.
   */
  actionCompleteLabelDuration?: number;
}

const ButtonBody = (props: ButtonBodyProps) => {
  const {
    children,
    icon: Icon,
    loading = false,
    actionCompleteLabel,
    actionCompleteLabelDuration = 3000,
  } = props;

  const [previousLoadingState, setPreviousLoadingState] = useState(loading);

  const [showActionCompleteLabel, setShowActionCompleteLabel] = useState(false);

  const hasActionCompleteLabel = !!actionCompleteLabel;

  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (previousLoadingState === loading) {
      return;
    }

    // Loading has just finished and action complete label is set
    if (
      hasActionCompleteLabel &&
      previousLoadingState === true &&
      loading === false
    ) {
      // show the action complete label
      setShowActionCompleteLabel(true);

      // hide it after the duration
      timeoutRef.current = window.setTimeout(() => {
        setShowActionCompleteLabel(false);
      }, actionCompleteLabelDuration);
    }

    // Loading has just started and action complete label is set
    if (
      hasActionCompleteLabel &&
      previousLoadingState === false &&
      loading === true
    ) {
      setShowActionCompleteLabel(false);
      window.clearTimeout(timeoutRef.current);
    }

    // update the previous loading state
    setPreviousLoadingState(loading);
  }, [
    actionCompleteLabelDuration,
    previousLoadingState,
    hasActionCompleteLabel,
    loading,
  ]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      {showActionCompleteLabel && (
        <span
          className="absolute inset-0 flex items-center justify-center"
          aria-hidden="true"
        >
          {actionCompleteLabel}
        </span>
      )}

      {loading && (
        <span
          className="absolute left-1/2 top-1/2 aspect-square h-1/2 -translate-x-1/2 -translate-y-1/2 text-current"
          aria-hidden="true"
        >
          <InlineSpinner className="size-full text-current" />
        </span>
      )}

      <span
        className={clsx('inline-flex items-center justify-center gap-2', {
          invisible: loading || showActionCompleteLabel,
        })}
      >
        {Icon ? <Icon /> : null} {children}
      </span>
    </>
  );
};

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonBodyProps &
  VariantProps<typeof buttonClasses> & {
    to?: string;
    target?: string;
    as?: 'button' | 'div';
  };
export const Button = forwardRef<HTMLElement, Props>(
  function Button(props, ref) {
    const {
      className,
      variant,
      size = 'md',
      icon,
      outline,
      to,
      children,
      loading = false,
      actionCompleteLabel,
      actionCompleteLabelDuration,
      disabled,

      ...rest
    } = props;

    const buttonClassName = clsx(
      buttonClasses({ variant, size, outline, loading }),
      className
    );

    if (typeof to === 'string') {
      return (
        <Link
          to={to}
          className={buttonClassName}
          ref={ref as React.Ref<HTMLAnchorElement>}
          rel={rest.rel}
          target={rest.target}
          onClick={
            props.onClick as MouseEventHandler<HTMLAnchorElement> | undefined
          }
        >
          <ButtonBody icon={icon} loading={loading}>
            {children}
          </ButtonBody>
        </Link>
      );
    }

    if (props.as === 'div') {
      return (
        <div className={buttonClassName}>
          <ButtonBody
            icon={icon}
            loading={loading}
            actionCompleteLabel={actionCompleteLabel}
            actionCompleteLabelDuration={actionCompleteLabelDuration}
          >
            {children}
          </ButtonBody>
        </div>
      );
    }

    return (
      <button
        className={buttonClassName}
        disabled={disabled || loading}
        ref={ref as React.Ref<HTMLButtonElement>}
        type="button"
        {...rest}
      >
        <ButtonBody
          icon={icon}
          loading={loading}
          actionCompleteLabel={actionCompleteLabel}
          actionCompleteLabelDuration={actionCompleteLabelDuration}
        >
          {children}
        </ButtonBody>
      </button>
    );
  }
);

import type { Configuration } from 'rollbar';

export const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: '1.0.0',
        guess_uncaught_frames: true,
      },
    },
    environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  },
  enabled: import.meta.env.VITE_ROLLBAR_ENVIRONMENT !== 'local',
};

import type { ITenant } from '@/types';
import logo from '@/assets/onesource-logo.svg';
import logoDark from '@/assets/onesource-logo-dark.svg';

export const OSC_TENANT: ITenant = {
  isPartner: false,
  name: 'OneSource Solutions',
  logo,
  logoDark,
  email: 'hello@osconnects.com',
  phone: '877-274-8632',
  facebookUrl: 'https://www.facebook.com/OneSourceSolutionsCorp',
  linkedInUrl:
    'https://www.linkedin.com/company/onesource-solutions-home-connections/',
  marketplaceName: 'Home Essentials',
  marketplaceDesc:
    'Welcome Home! Discover handpicked offers tailored to your new address, from lawn care to professional painting—transform your new space into your dream home.',
  termsOfService: 'https://www.osconnects.com/terms-of-service',
  privacyPolicy: 'https://www.osconnects.com/privacy-policy',
};

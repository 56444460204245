import type { ITenant } from '@/types';
import { useCurrentPartner } from './useCurrentPartner';
import { OSC_TENANT } from '@/config/oscTenant';

export function useTenant(): ITenant {
  const partner = useCurrentPartner();

  if (partner) {
    return {
      isPartner: true,
      name: partner.name,
      phone: partner.StorePhone,
      email: partner.StoreEmail,
      logo: partner.LogoFile,
      logoDark: partner.LogoFile,
      facebookUrl: null,
      linkedInUrl: null,
      marketplaceName: partner.MarketplaceName ?? partner.name,
      marketplaceDesc: partner.MarketplaceDesc,
      termsOfService: partner.TermsOfService ?? OSC_TENANT.termsOfService,
      privacyPolicy: partner.PrivacyPolicy ?? OSC_TENANT.privacyPolicy,
    };
  }

  return OSC_TENANT;
}

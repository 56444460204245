import { Loader2Icon } from 'lucide-react';

interface Props {
  className?: string;
}

export const InlineSpinner = (props: Props) => {
  const { className } = props;
  return (
    <div className={className} aria-label="Loading...">
      <Loader2Icon className="size-full animate-spin" />
    </div>
  );
};

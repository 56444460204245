import { useRollbar } from '@rollbar/react';
import { useCallback } from 'react';
import type { LogArgument } from 'rollbar';
import invariant from '@/lib/invariant';

function isLogArgument(arg: unknown): arg is LogArgument {
  return (
    ['string', 'function', 'object'].includes(typeof arg) ||
    arg instanceof Error ||
    arg instanceof Date ||
    Array.isArray(arg)
  );
}

export function useReportError() {
  const rollbar = useRollbar();

  return useCallback(
    (error: unknown) => {
      invariant(isLogArgument(error), 'Invalid log argument');
      if (import.meta.env.MODE === 'development') {
        console.error(error);
      } else {
        rollbar.error(error);
      }
    },
    [rollbar]
  );
}

import { useTenant } from '@/hooks/useTenant';
import clsx from 'clsx';
import { Phone } from 'lucide-react';
import { UserNavigation } from '../UserNavigation';

export function TopNav() {
  const tenant = useTenant();

  return (
    <>
      <nav
        aria-label="Primary Navigation"
        id="main-nav"
        className={clsx('flex items-center gap-x-8 py-4 font-heading')}
      >
        {tenant.phone && (
          <div className="hidden sm:block">
            <a
              href={`tel:${tenant.phone.replace(/\D/g, '')}`}
              className="block"
            >
              <span className="flex items-center justify-center gap-2 text-xl font-extrabold xl:justify-end">
                <Phone className="text-app-text size-5" /> {tenant.phone}
              </span>
              <span className="block text-xs">
                Get Help from a move specialist.
              </span>
            </a>
          </div>
        )}

        <UserNavigation />
      </nav>
    </>
  );
}

import axios, { type RawAxiosRequestHeaders } from 'axios';

import type { ICRMApiResponse } from '@/types';

export async function crmRequest<ResponseData = Record<string, unknown>>(
  Method: string,
  data: Record<string, unknown>,
  connection: {
    APIKey: string;
    baseURL: string;
    headers: Partial<RawAxiosRequestHeaders>;
  }
) {
  const { APIKey, baseURL, headers = {} } = connection;
  const params = {
    APIKey,
    Method,
    ...data,
  };

  const response = await axios.post<ICRMApiResponse<ResponseData>>(
    '/',
    params,
    {
      headers,
      baseURL,
      withCredentials: false,
    }
  );

  if (response.data.ResultCode < 200 || response.data.ResultCode >= 300) {
    throw new Error(response.data.ResultMessage ?? 'Unknown error');
  }

  return response.data;
}

import { useTenant } from '@/hooks/useTenant';
import clsx from 'clsx';

interface Props {
  className?: string;
  variant?: 'light' | 'dark';
}

export const PageLogo = (props: Props) => {
  const { className, variant = 'dark' } = props;

  const tenant = useTenant();

  const logoSrc = variant === 'dark' ? tenant.logoDark : tenant.logo;

  if (logoSrc) {
    return (
      <img
        src={logoSrc}
        alt={tenant.marketplaceName}
        width="170"
        height="40"
        className={clsx('h-[40px] w-[170px] object-contain', className)}
      />
    );
  }

  return null;
};

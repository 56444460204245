interface ICookieOptions {
  days?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  'max-age'?: number;
  sameSite?: boolean | 'strict' | 'lax' | 'none';
}

export function getCookie(name: string) {
  const c = RegExp(`(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`).exec(
    document.cookie ?? ''
  )?.[1];

  if (c) {
    return decodeURIComponent(c);
  }
}
export function setCookie(
  name: string,
  value: string | number,
  opts: ICookieOptions = {}
) {
  /*If options contains days then we're configuring max-age*/
  if (opts.days) {
    opts['max-age'] = opts.days * 60 * 60 * 24;

    /*Deleting days from options to pass remaining opts to cookie settings*/
    opts.days = undefined;
  }

  /*Configuring options to cookie standard by reducing each property*/
  const stringifiedOpts = Object.entries(opts).reduce(
    (accumulatedStr, [k, v]) => `${accumulatedStr}; ${k}=${v}`,
    ''
  );

  /*Finally, creating the key*/
  document.cookie = `${name}=${encodeURIComponent(value)}${stringifiedOpts}`;
}

export function deleteCookie(name: string, opts: ICookieOptions = {}) {
  setCookie(name, '', { 'max-age': -1, ...opts });
}

import { crmRequest } from './crmRequest';

export async function crmWSApiRequest<ResponseData = Record<string, unknown>>(
  method: string,
  data: Record<string, unknown>
) {
  return crmRequest<ResponseData>(method, data, {
    APIKey: import.meta.env.VITE_CRM_API_KEY,
    baseURL: import.meta.env.VITE_CRM_ENDPOINT,
    headers: {
      AccessToken: import.meta.env.VITE_CRM_ACCESS_TOKEN,
    },
  });
}
